<template>
  <breadcrumb-page :list="breadcrumb_links"></breadcrumb-page>

  <div id="maincontent_container">
    <submenu-support menu="contact"></submenu-support>

      <div class="submenu-content">
        <h3>Ma Labs Contact Form</h3>

        <div v-if="result.status == 'done'" class="alert alert-success">{{ result.message }}</div>
        <div v-else-if="result.status == 'error'" class="alert alert-danger">{{ result.message }}</div>

        <p>Please provide the following information and our team will respond to you shortly.</p>
        <p> <span class="red">*</span> Required Information</p>

        <form class="row g-3 ">
          <div class="col-md-10" :class="{'alert alert-danger': data.subject.error}">
            <label class="form-label">Subject <span class="red">*</span></label>
            <div v-if="data.subject.error">This field is required</div>
            <select class="form-select" v-model="data.subject.input" ref="subject">
              <option value="">-- Select a topic --</option>
              <option v-for="(row, key) in subject_list" :value="key">{{row.topic}}</option>
              <!-- <option value="Account Login Assistance">Account Login Assistance</option> -->
            </select>
          </div>

          <div class="col-md-5" :class="{'alert alert-danger': data.first_name.error}">
            <label class="form-label">First Name <span class="red">*</span></label>
            <div v-if="data.first_name.error">This field is required</div>
            <input type="text" class="form-control" v-model="data.first_name.input" ref="first_name">
          </div>

          <div class="col-md-5" :class="{'alert alert-danger': data.last_name.error}">
            <label class="form-label">Last Name <span class="red">*</span></label>
            <div v-if="data.last_name.error">This field is required</div>
            <input type="text" class="form-control" v-model="data.last_name.input" ref="last_name">
          </div>

          <div class="col-md-10" :class="{'alert alert-danger': data.email.error}">
            <label class="form-label">Email Address <span class="red">*</span></label>
            <div v-if="data.email.error">{{data.email.message}}</div>
            <input type="email" class="form-control" v-model="data.email.input" ref="email">
          </div>

          <div class="col-md-3" :class="{'alert alert-danger': data.phone.error}">
            <label class="form-label">Phone <span class="red">*</span></label>
            <div v-if="data.phone.error">This field is required</div>
            <input type="text" class="form-control" v-model="data.phone.input" ref="phone">
          </div>

          <div class="col-2">
            <label for="inputAddress" class="form-label">Extension</label>
            <input type="text" class="form-control" v-model="data.phone_ext.input" ref="phone_ext">
          </div>

          <div class="col-10" :class="{'alert alert-danger': data.company.error}">
            <label class="form-label">Company/Organization Name <span class="red">*</span></label>
            <div v-if="data.company.error">This field is required</div>
            <input type="text" class="form-control" v-model="data.company.input" ref="company">
          </div>

          <div class="col-md-10" :class="{'alert alert-danger': data.country.error}">
            <label class="form-label">Country <span class="red">*</span></label>
            <div v-if="data.country.error">This field is required</div>
            <select class="form-select" v-model="data.country.input" @change="handleCountry" ref="country">
              <select-option-list-country />
            </select>
          </div>

          <div class="col-md-10">
            <label for="inputCity" class="form-label">Address</label>
            <input type="text" class="form-control" v-model="data.address.input" ref="address">
          </div>

          <div class="col-md-10">
            <label for="inputCity" class="form-label">City</label>
            <input type="text" class="form-control" v-model="data.city.input" ref="city">
          </div>

          <div class="col-md-5" v-if="show" :class="{'alert alert-danger': data.state.error}">
            <label for="inputState" class="form-label">State <span class="red">*</span></label>
            <div v-if="data.state.error">This field is required</div>
            <select class="form-select" v-model="data.state.input" ref="state">
              <select-option-list-state />
            </select>
          </div>

          <div class="col-md-5" v-if="show" :class="{'alert alert-danger': data.zip.error}">
            <label for="inputZip" class="form-label">Zip/Postal Code <span class="red">*</span></label>
            <div v-if="data.zip.error">This field is required</div>
            <input type="text" class="form-control" v-model="data.zip.input" ref="zip">
          </div>

          <div class="col-md-5" :class="{'alert alert-danger': data.is_customer.error}">
            <label class="form-label">Are you a Ma Labs customer? <span class="red">*</span></label>
            <div v-if="data.is_customer.error">This field is required</div>
            <select class="form-select" v-model="data.is_customer.input" ref="is_customer">
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select> 
          </div>

          <div class="col-md-5" :class="{'alert alert-danger': data.customer_id.error}">
            <label class="form-label">Customer ID</label>
            <div v-if="data.customer_id.error">This field is required</div>
            <input type="text" class="form-control" v-model="data.customer_id.input" ref="customer_id">
          </div>

          <div class="col-md-10" :class="{'alert alert-danger': data.message.error}">
            <label class="form-label">Message <span class="red">*</span></label>
            <div v-if="data.message.error">This field is required</div>
            <textarea class="form-control" id="" rows="5" v-model="data.message.input" ref="message"></textarea>
          </div>

          <div class="col-md-10">
            <button v-if="waiting" class="btn btn-primary btn-lg" disabled>
                  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  Submit
              </button>
            <button v-else type="submit" class="btn btn-primary btn-lg" @click.prevent="handleSubmit">Submit</button>
          </div>
        </form>
        
      </div>

  </div>

  <div id="google_recaptcha"></div>
</template>

<script>
import SubmenuSupport from "../../components/nav/SubmenuSupport.vue";
import BreadcrumbPage from "../../components/BreadcrumbPage.vue";
import SelectOptionListCountry from "../../components/SelectOptionListCountry.vue";
import SelectOptionListState from "../../components/SelectOptionListState.vue";
import { insertReCaptchaScript, handleReCaptcha, checkEmail } from '../../utils/common'
import { ref, onMounted } from 'vue'
import { getAPI } from '../../utils/axios-api'

export default {
  name: 'ContactUs',
  components: { SubmenuSupport, BreadcrumbPage, SelectOptionListCountry, SelectOptionListState },
  props: [ "topic" ], 
  setup(props, context) {
    const show = ref(true)
    const result = ref({status: '', message: ''})
    const waiting = ref(false)
    
    const subject = ref(null)
    const first_name = ref(null)
    const last_name = ref(null)
    const email = ref(null)
    const phone = ref(null)
    const phone_ext = ref(null)
    const company = ref(null)
    const country = ref(null)
    const address = ref(null)
    const city = ref(null)
    const state = ref(null)
    const zip = ref(null)
    const is_customer = ref(null)
    const customer_id = ref(null)
    const message = ref(null)

    const data = ref({
      subject: {input: null, error: false},
      first_name: {input: null, error: false},
      last_name: {input: null, error: false},
      email: {input: null, error: false, message: null},
      phone: {input: null, error: false},
      phone_ext: {input: null, error: false},
      company: {input: null, error: false},
      country: {input: null,  error: false},
      address: {input: null, error: false},
      city: {input: null, error: false},
      state: {input: null, error: false},
      zip: {input: null, error: false},
      is_customer: {input: null, error: false},
      customer_id: {input: null, error: false},
      message: {input: null, error: false},
    })
    

    const subject_list = {
      sales: {topic: "New Customer", email: "sales@malabs.com"},
      crm1: { topic: "Contact Sales (Product, Quote)", email: "sales@malabs.com"},
      crm2: { topic: "Existing Order Status", email: "sales@malabs.com"},
      //server: { topic: "Server", email: "servers@malabs.com", cc: "webmaster@malabs.com"},
      server: { topic: "Server", email: "sales@malabs.com", cc: "webmaster@malabs.com"},
      returns: { topic: "Returns", email: "rma@malabs.com"},
      // tech: {topic: "Post-sales Technical Support", email: "techsupport@malabs.com"},
      mis: { topic: "Account Login Assistance", email: "webmaster@malabs.com"},
      credit: { topic: "Account Application Status", email: "whcredit@malabs.com"},
      others: { topic: "Others", email: "sales@malabs.com"},
      //mis2: { topic: "Report Suspicious Activity ", email: "webmaster@malabs.com"}
    } 
    
    //breadcrumb
    const breadcrumb_links = ref([])
    breadcrumb_links.value.push({text: "Support", link: ""})
    breadcrumb_links.value.push({text: "Contact Us", link: "ContactUs"})

    //add script for google reCaptcha to template
    onMounted(() => {
        insertReCaptchaScript("google_recaptcha")
    })

    //polulate default subject if there is any.
    if (props.topic in subject_list) {
      data.value.subject.input = props.topic
    }

    //if country is US, show state and zip code. Otherwise, hide them.
    const handleCountry = () => {
      if (data.value.country.input == 'US') show.value = true
      else show.value = false
    }

    //form validation
    function validate() {
      let valid = true
      if (data.value.subject.input != "" && data.value.subject.input != null) data.value.subject.error = false
      else {
        valid = false 
        data.value.subject.error = true
        subject.value.focus()
      }


      if (!!data.value.first_name.input) data.value.first_name.error = false
      else {
        valid = false        
        data.value.first_name.error = true
        first_name.value.focus()
      }

      if (!!data.value.last_name.input) data.value.last_name.error = false
      else {
        valid = false
        data.value.last_name.error = true
        last_name.value.focus()
      }

      if (!!data.value.email.input) {
        if (checkEmail(data.value.email.input) == true) 
          data.value.email.error = false
        else {
          valid = false 
          data.value.email.error = true
          data.value.email.message = "The email address is invalid."
        }
      }
      else {
        valid = false
        data.value.email.error = true
        data.value.email.message = "The email address is required."
        email.value.focus()
      }

      if (!!data.value.phone.input) data.value.phone.error = false
      else {
        valid = false
        data.value.phone.error = true
        phone.value.focus()
      }

      if (!!data.value.company.input) data.value.company.error = false
      else {
        valid = false
        data.value.company.error = true
        company.value.focus()
      }

      if (data.value.country.input != "" && data.value.country.input != null) {
        data.value.country.error = false
        
        //clear state and zip if country is US
        if (data.value.country.input != "US") {
          data.value.state.input = ""
          data.value.zip.input = ""
        }
      }
      else {
        valid = false
        data.value.country.error = true
        country.value.focus()
      }

      if (data.value.state.input != "" && data.value.state.input != null) data.value.state.error = false
      else {
        if (data.value.country.input != "US") data.value.state.error = false
        else {
          valid = false
          data.value.state.error = true
          state.value.focus()
        }
      }

      if (!!data.value.zip.input) data.value.zip.error = false
      else {
        if (data.value.country.input != "US") data.value.zip.error = false
        else {
          valid = false
          data.value.zip.error = true
          zip.value.focus()
        }
      }

      if (data.value.is_customer.input != "" && data.value.is_customer.input != null) data.value.is_customer.error = false
      else {
        valid = false
        data.value.is_customer.error = true
        is_customer.value.focus()
      }

      if (!!data.value.customer_id.input) data.value.customer_id.error = false
      else {
        if (data.value.is_customer.input == "No" || data.value.is_customer.input == '' || data.value.is_customer.input == null) data.value.customer_id.error = false
        else {
          valid = false
          data.value.customer_id.error = true
          customer_id.value.focus()
        }
      
      }

      if (data.value.message.input != "" && data.value.message.input != null) data.value.message.error = false
      else {
        valid = false
        data.value.message.error = true
        message.value.focus()
      }
      
      return valid
    }

    const handleSubmit = async () => {
      
      let result_recaptcha = await handleReCaptcha(grecaptcha)
      if (result_recaptcha["is_human"] == true) {

        if (validate() == true) {

          //payload
          let payload = {
            subject: subject_list[data.value.subject.input],
            first_name: data.value.first_name.input,
            last_name: data.value.last_name.input,
            email: data.value.email.input,
            phone: data.value.phone.input,
            phone_ext: data.value.phone_ext.input,
            company: data.value.company.input,
            country: data.value.country.input,
            address: data.value.address.input,
            city: data.value.city.input,
            state: data.value.state.input,
            zip: data.value.zip.input,
            is_customer: data.value.is_customer.input,
            customer_id: data.value.customer_id.input,
            message: data.value.message.input
          }
          
          //call api to process the form
          getAPI
          .post('/account/contact-us/', payload)
          .then(response => {
            //clear form data
            data.value.subject.input = ""
            data.value.first_name.input = ""
            data.value.last_name.input = ""
            data.value.email.input = ""
            data.value.phone.input = ""
            data.value.phone_ext.input = ""
            data.value.company.input = ""
            data.value.country.input = ""
            data.value.address.input = ""
            data.value.city.input = ""
            data.value.state.input = ""
            data.value.zip.input = ""
            data.value.is_customer.input = ""
            data.value.customer_id.input = ""
            data.value.message.input = ""
          
            //show result
            result.value["status"] = 'done'
            result.value["message"] = response.data["message"]
            window.scrollTo(0,0);
          })
          .catch(err => {
            result.value["status"] = 'error'
            try {
              result.value["message"] = err.response.data["message"]
            }
            catch {
              result.value["message"] = "Something is wrong. Failed to send the email."
            }
            window.scrollTo(0,0);
          })
        }
      }
      else {
        result.value["status"] = 'error'
        result.value["message"] = result_recaptcha["message"]
        window.scrollTo(0,0);
      }
    }

    return {
            show, 
            subject_list,

            subject,
            first_name,
            last_name,
            email,
            phone,
            phone_ext,
            company,
            country,
            address,
            city,
            state,
            zip,
            is_customer,
            customer_id,
            message,

            data,
            result,
            waiting,
            handleCountry,
            handleSubmit,

            breadcrumb_links }
  }
}
</script>

<style scoped>


.red {color:red}
label {font-weight:bold;}


</style>
